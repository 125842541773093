var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container page__inner"},[_c('div',{staticClass:"page__content--wider"},[_c('div',{staticClass:"page__head2"},[_c('div',{staticClass:"page__head__container align-items-center justify-content-between"},[_vm._m(0),_c('div',{staticClass:"page__head__actions"},[_c('div',{staticClass:"d-flex"},[_c('button',{staticClass:"button page__body__header__button button--grey text--capital",staticStyle:{"width":"165px"},attrs:{"type":"button"},on:{"click":_vm.toggleFilterModal}},[_c('span',{staticClass:"d-inline-flex mr-2"},[_vm._v("filter")])])])])])]),_c('div',{staticClass:"page__body"},[(_vm.filterText)?_c('div',{staticStyle:{"display":"flex","width":"50%","font-size":"14px"}},[_c('div',{staticStyle:{"width":"80%"}},[_vm._v(" "+_vm._s(_vm.filterText)+" ")]),_c('div',{staticStyle:{"display":"flex","width":"10%"}},[_c('span',{staticStyle:{"cursor":"pointer","font-size":"17px"},on:{"click":_vm.editFilter}},[_c('i',{staticClass:"bi bi-pencil-square",staticStyle:{"padding-right":"5px"}})]),_c('span',{staticStyle:{"cursor":"pointer","font-size":"17px"},on:{"click":_vm.deleteFilter}},[_c('i',{staticClass:"bi bi-x-circle-fill",staticStyle:{"padding-left":"5px"}})])])]):_vm._e(),_c('table',{staticClass:"customtable w-100"},[_vm._m(1),(_vm.userIsNotAuthorised)?_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"12"}},[_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center p5"},[_c('img',{attrs:{"src":require("@/assets/img/empty.svg"),"alt":"delete","srcset":""}}),_c('span',{staticClass:"d-block text--danger text--600 text--medium mt-3 mb-5"},[_vm._v(_vm._s(_vm.errorResponse))])])])])]):_c('tbody',_vm._l((_vm.tasks),function(task){return _c('tr',{key:task.taskKey},[_c('td',[_c('router-link',{staticClass:"table__body__text table__text text--capital text--primary",attrs:{"tag":"a","to":{
                  name: 'underwriting_accounts',
                  params: { id: task.loanAccountKey, taskKey: task.taskKey },
                }}},[_vm._v(" "+_vm._s(task.customerName))])],1),_c('td',{staticStyle:{"padding-right":"15px"}},[_c('span',{staticClass:"table__body__text table__text text--capital",staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(task.loanProductName)+" ")])]),_c('td',[_c('span',{staticClass:"table__body__text table__text text--capital"},[_vm._v(" ₦"+_vm._s(_vm._f("formatCurrencyPipe")(task.loanAmount))+" ")])]),_c('td',[_c('span',{staticClass:"table__body__text table__text text--capital"},[_vm._v(" "+_vm._s(task.loanOriginator)+" ")])]),_c('td',{staticStyle:{"padding-right":"15px"}},[_c('span',{staticClass:"table__body__text table__text text--capital",staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(task.branchName)+" ")])]),_c('td',[_c('span',{staticClass:"table__body__text table__text text--capital"},[_vm._v(" "+_vm._s(_vm._f("moment")(task.lastModifiedDate))+" ")])]),_c('td',[_c('span',{staticClass:"table__body__text table__text text--capital"},[_vm._v(" "+_vm._s(task?.currentApprovalLevelName)+" ")])]),_c('td',[_c('router-link',{staticClass:"table__body__text table__text text--capital text--primary",attrs:{"tag":"a","to":{
                  name: 'edit_loan_account',
                  params: { loanAccountKey: task.loanAccountKey },
                }}},[_vm._v(" Edit")])],1)])}),0)]),(!_vm.userIsNotAuthorised)?_c('div',{staticClass:"pagination"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between w-100"},[_c('div',{staticClass:"text--capital text--regular pagination__text"},[_vm._v(" showing "),_c('span',{staticClass:"d-inline-block text--primary"},[_c('label',{attrs:{"for":""}},[_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.fetchAccount.pageSize),expression:"fetchAccount.pageSize",modifiers:{"number":true}}],staticClass:"select select--lg select--page",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.$set(_vm.fetchAccount, "pageSize", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.changePageSize()}]}},_vm._l((_vm.pagesizes),function(psize){return _c('option',{key:psize.id,attrs:{"id":psize.id}},[_vm._v(" "+_vm._s(psize.size)+" ")])}),0)])]),_c('span',{staticClass:"d-inline-block text--primary"},[_vm._v(_vm._s(_vm.paging.currentPage || _vm.paging.pageIndex))]),_vm._v(" to "),_c('span',{staticClass:"d-inline-block text--primary"},[_vm._v(_vm._s(_vm.paging.totalPages))])]),_c('div',{staticClass:"pagination__buttons d-flex align-items-center justify-content-between"},[(_vm.paging.hasPreviousPage)?_c('button',{staticClass:"button button--sm text--regular",on:{"click":function($event){return _vm.stepPage('previous')}}},[_c('ion-icon',{attrs:{"name":"chevron-back-outline"}})],1):_vm._e(),(!_vm.isLargeSizePaged)?_c('div',{staticClass:"d-flex"},_vm._l((_vm.paging.totalPages),function(num){return _c('button',{key:num,staticClass:"button text--regular button--sm ml-2",class:{
                  'button--primary text--white':
                    _vm.paging.pageIndex === num || _vm.paging.currentPage === num,
                },attrs:{"disabled":_vm.paging.pageIndex === num || _vm.paging.currentPage === num},on:{"click":function($event){return _vm.changePage(num)}}},[_vm._v(" "+_vm._s(num)+" ")])}),0):_c('div',{staticClass:"text--capital text--regular pagination__text"},[_vm._v(" Page "),_c('span',{staticClass:"d-inline-block text--primary"},[_vm._v(_vm._s(_vm.paging.currentPage))]),_vm._v(" of "),_c('span',{staticClass:"d-inline-block text--primary"},[_vm._v(_vm._s(_vm.paging.totalPages))]),_vm._v(" from "),_c('span',{staticClass:"d-inline-block text--primary"},[_vm._v(_vm._s(_vm.paging.totalCount))]),_vm._v(" Records ")]),(_vm.paging.hasNextPage)?_c('button',{staticClass:"button text--regular button--sm ml-2",on:{"click":function($event){return _vm.stepPage('next')}}},[_c('ion-icon',{attrs:{"name":"chevron-forward-outline"}})],1):_vm._e()])])]):_vm._e()]),_c('div',{staticClass:"modal modal__right",attrs:{"id":"statusModal","tabindex":"","role":"dialog","aria-labelledby":"modalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal__dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal__content"},[_c('div',{staticClass:"modal__head"},[_c('div',{staticClass:"modal__head__icon"},[_c('a',{staticClass:"modal__close modal__close--black",attrs:{"data-dismiss":"modal"}},[_c('ion-icon',{attrs:{"name":"arrow-back-outline","data-dismiss":"modal","id":"statusFormCloseButton","aria-label":"Close","disabled":_vm.isSaving},on:{"click":_vm.clearStatusForm}})],1)]),_c('h5',{staticClass:"modal__head__title text--capital"},[_vm._v("Change Status")])]),_c('div',{staticClass:"modal__body"},[_c('form',{staticClass:"form",on:{"submit":_vm.saveStatusForm}},[_c('div',{staticClass:"form__item"},[_c('div',{staticClass:"form__item mb-0"},[_c('label',{staticClass:"form__label"},[_vm._v("Status")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.statusForm.status),expression:"statusForm.status"}],staticClass:"select select--lg",class:{
                      'input-required': _vm.statusFormErr.status,
                    },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.statusForm, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(" Select Status ")]),_vm._l((_vm.reviewStatuses),function(item){return _c('option',{key:item,domProps:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])})],2)])]),_c('div',{staticClass:"form__item"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.statusForm.approvalComment),expression:"statusForm.approvalComment"}],staticClass:"input form__input",attrs:{"cols":"5","rows":"5","placeholder":"Comment"},domProps:{"value":(_vm.statusForm.approvalComment)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.statusForm, "approvalComment", $event.target.value)}}})]),_c('div',{staticClass:"form__item mt-5"},[_c('div',{staticClass:"form__action"},[(!_vm.isSaving)?_c('button',{staticClass:"button form__button form__button--lg w-30 save-changes",attrs:{"type":"submit"}},[_vm._v(" Update Status ")]):_vm._e(),(_vm.isSaving)?_c('a',{staticClass:"button form__button form__button--lg w-25 save-changes",attrs:{"role":"button"}},[_c('div',{staticClass:"spinner-border text-light",attrs:{"role":"status"}})]):_vm._e(),(!_vm.isSaving)?_c('a',{staticClass:"modal__close form__action__text form__action__text--danger text--capital ml6",attrs:{"role":"button","data-dismiss":"modal"},on:{"click":_vm.clearStatusForm}},[_vm._v(" cancel ")]):_vm._e()])])])])])])]),_c('div',{staticClass:"toaster show"},[(_vm.alert.show)?_c('div',{staticClass:"toaster__container",class:{
          'toaster--success': _vm.alert.status === 'success',
          'toaster--error': _vm.alert.status === 'fail',
          'toaster--warn': _vm.alert.status === 'warn',
        }},[_c('div',{staticClass:"text-right"},[_c('span',{staticClass:"fa fa-times",on:{"click":function($event){_vm.alert.show = false}}})]),_c('div',[_c('span',{staticClass:"d-block text--small text--600 text--white"},[_vm._v(_vm._s(_vm.alert.title))]),_c('span',{staticClass:"d-block text--tiny text--400 text--white"},[_vm._v(_vm._s(_vm.alert.description))])])]):_vm._e()])]),_c('CreateFilter',{attrs:{"show":_vm.showFilterModal,"filterEditValue":_vm.filterEdit,"showEditedValue":_vm.showEditedValue,"filterHeaderName":'Underwriting Dashboard',"filterCategory":"underwriting"},on:{"close":_vm.toggleFilterModal,"send-activate-filter":_vm.sendFilter,"reload":_vm.reloadFilter,"alertError":_vm.alertError}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"page__head__text"},[_c('h1',{staticClass:"page__head2__title"},[_vm._v("Underwriter Dashboard")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('thead',[_c('tr',[_c('th',{staticClass:"table__header__text table__text text--capital",staticStyle:{"width":"12%"},attrs:{"scope":"col"}},[_vm._v(" Customer Full Name ")]),_c('th',{staticClass:"table__header__text table__text text--capital",staticStyle:{"width":"11%"},attrs:{"scope":"col"}},[_vm._v(" Loan Product ")]),_c('th',{staticClass:"table__header__text table__text text--capital",staticStyle:{"width":"11%"},attrs:{"scope":"col"}},[_vm._v(" Loan Amount ")]),_c('th',{staticClass:"table__header__text table__text text--capital",staticStyle:{"width":"12%"},attrs:{"scope":"col"}},[_vm._v(" Loan Originator ")]),_c('th',{staticClass:"table__header__text table__text text--capital",staticStyle:{"width":"11%"},attrs:{"scope":"col"}},[_vm._v(" Branch ")]),_c('th',{staticClass:"table__header__text table__text text--capital",staticStyle:{"width":"11%"},attrs:{"scope":"col"}},[_vm._v(" Loan Account Last Modified Date ")]),_c('th',{staticClass:"table__header__text table__text text--capital",staticStyle:{"width":"13%"},attrs:{"scope":"col"}},[_vm._v(" Review Level ")]),_c('th',{staticClass:"table__header__text table__text text--capital",staticStyle:{"width":"5%"},attrs:{"scope":"col"}},[_vm._v(" Action ")])])])
}]

export { render, staticRenderFns }